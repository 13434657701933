import React, {useEffect, useState} from "react";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import Image from "next/image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faTwitter, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import Link from "next/link";
import {IconButton} from "@mui/material";
import {useTranslation} from "next-i18next";
import {checkMobile} from "../../utils/helper";

const Column = ({children}) => (
  <div className={'w-1/3'}>
    {children}
  </div>
)

const SocialLink = ({href, icon}) => (
  <a href={href} target='_blank' rel='noreferrer'>
    <IconButton variant='text' className='text-black hover:text-primary'>
      <FontAwesomeIcon icon={icon} className='w-[1em] text-base'/>
    </IconButton>
  </a>
)

const LinkGroup = ({title, children}) => (
  <div className="flex flex-col gap-1 items-start mb-4 last:mb-0">
    <h4 className="mb-2 font-semibold">{title}</h4>
    {children}
  </div>
)

const FooterLink = ({children, ...props}) => (
  <Link {...props}>
    <a className="text-gray-500">{children}</a>
  </Link>
)

export default function Footer({}) {

  const { t, i18n } = useTranslation()
  const [loaded, setLoaded] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (loaded) {
      setIsMobile(checkMobile())
    }
  }, [loaded])

  return (
    <>
      <Wrapper className={'border-t'}>
        <Container>
          <footer className={isMobile ? 'flex flex-col lg:flex-row items-center' : 'flex flex-col lg:flex-row'}>
            <div className={'w-64 shrink min-w-[200px] text-center'}>
              <div className="mb-4">
                <Image src={require('assets/logo-new.png')} alt={'DaMaWish'}/>
              </div>
              <svg className="w-full text-[#7c7c7c] mb-4 pl-1" viewBox="0 0 142 13">
                <text x="0" y="10" className={i18n.language === 'en' ? "text-[10px] font-semibold" : "text-[9px] font-semibold"} fill='currentColor'>{t('home:footer_slogan')}</text>
              </svg>
              <div className="flex justify-between px-3">
                <SocialLink href="https://www.facebook.com/damawish" icon={faFacebookF}/>
                <SocialLink href="https://www.instagram.com/damawishcom/?hl=en" icon={faInstagram}/>
                <SocialLink href="https://twitter.com/damawish" icon={faTwitter}/>
                <SocialLink href="https://www.linkedin.com/company/damawish/" icon={faLinkedinIn}/>
              </div>
            </div>
            <div className="grow basis-10 shrink-0"/>
            <div className={isMobile ? "grow flex gap-2 basis-[100px] shrink" : "grow flex gap-2 basis-[700px] shrink"}>
              <Column>
                <LinkGroup title={t('home:footer_about')}>
                  <FooterLink href="/about">{t('home:footer_about_us')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_about_help_center')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_about_investor_relations')}</FooterLink>
                  <FooterLink href="https://damamap.com/cat/strains">{t('home:footer_about_strain_database')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_about_career')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_about_contact')}</FooterLink>
                </LinkGroup>
              </Column>
              <Column>
                <LinkGroup title={t('home:footer_business_solution')}>
                  <FooterLink href="https://admin.damamaps.com">{t('home:footer_list_your_cannabiz')}</FooterLink>
                  {/*<FooterLink href="">List your CBD store</FooterLink>*/}
                  {/*<FooterLink href="">List your clinic</FooterLink>*/}
                  {/*<FooterLink href="https://admin.damamaps.com">Business portal</FooterLink>*/}
                </LinkGroup>
                <LinkGroup title={t('home:footer_legal')}>
                  <FooterLink href="/">{t('home:footer_terms_of_use')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_privacy_policy')}</FooterLink>
                </LinkGroup>
              </Column>
              <Column>
                <LinkGroup title={t('home:footer_discover')}>
                  <FooterLink href="/map">{t('home:footer_discover_dispensary')}</FooterLink>
                  <FooterLink href="/map">{t('home:footer_doctors')}</FooterLink>
                  <FooterLink href="https://damamap.com/news">{t('home:footer_news')}</FooterLink>
                  <FooterLink href="https://damamap.com/bbs">{t('home:footer_social_netowrking')}</FooterLink>
                  <FooterLink href="/">{t('home:footer_learningcenter')}</FooterLink>
                  <FooterLink href="/sign-up">{t('home:sign_up')}</FooterLink>
                  <FooterLink href="/login">{t('home:log_in')}</FooterLink>
                </LinkGroup>
              </Column>
            </div>
          </footer>
        </Container>
      </Wrapper>
      <Wrapper className={'border-t py-4'}>
        <Container className={'text-center text-sm'}>
          {t('home:copyRight')}
        </Container>
      </Wrapper>
    </>
  )
}
