const { BACKEND_ROOT_URL, SEND_GRID_TOKEN, JWT_TOKEN, POST_MARK_TOKEN } = require("../constants");
const _ = require('lodash');
const { backend_api } = require("../lib/client");
const jwt = require('jsonwebtoken');
const { isMobile } = require('react-device-detect')
// const categoryMappings = JSON.parse(fs.readFileSync(path.join(__dirname, '../data/categoryMappings.json')));

const splitToArray = (input, delimiter = ",") => {
    return Array.isArray(input) ? input : input.split(delimiter)
}

const createResponse = (data, options) => {
    return {
        statusCode: 200,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
        },
        body: JSON.stringify(data),
        ...options
    }
};

const createError = (e, statusCode = 400) => {
    return createResponse(_.get(e, 'response.data') || e, { statusCode });
}

const processPublicImg = (rawLink) => {
    return rawLink.indexOf('assets/') !== -1
        ? `${BACKEND_ROOT_URL}/${rawLink}`
        : rawLink;
}

const extractId = (str, key) => str.split(key)[1];

const getOpenHours = (body) => {
    let list =  _.range(1, 8).map(idx => body[`open_hour_${idx}`]);
    let canConvert = true
    for (let i = 0; i < list.length; i++) {
        let localCanConvert = list[i].includes(':') &&
            !list[i].includes('am') && !list[i].includes('AM') && !list[i].includes('pm')
            && !list[i].includes('PM')
            && !list[i].includes('24 Hours') && !list[i].includes('Closed')
        if (!localCanConvert) {
            canConvert = localCanConvert
        }
    }
    if (canConvert) {
        let arranged = {
            monday: {
                open: tConvert(list[0].split('-')[0]),
                close: tConvert(list[0].split('-')[1])
            },
            tuesday: {
                open: tConvert(list[1].split('-')[0]),
                close: tConvert(list[1].split('-')[1])
            },
            wednesday: {
                open: tConvert(list[2].split('-')[0]),
                close: tConvert(list[2].split('-')[1])
            },
            thursday: {
                open: tConvert(list[3].split('-')[0]),
                close: tConvert(list[3].split('-')[1])
            },
            friday: {
                open: tConvert(list[4].split('-')[0]),
                close: tConvert(list[4].split('-')[1])
            },
            saturday: {
                open: tConvert(list[5].split('-')[0]),
                close: tConvert(list[5].split('-')[1])
            },
            sunday: {
                open: tConvert(list[6].split('-')[0]),
                close: tConvert(list[6].split('-')[1])
            },
        }
        return arranged
    }
}

const productsFilter = (processedProducts, filterOptions) => {
    if (!filterOptions) {
        return processedProducts;
    }
    const needFilterCategory = Boolean(filterOptions.categories);
    const needFilterBrands = Boolean(filterOptions.brands);
    const needFilterMaxPrice = Boolean(filterOptions.maxPrice);
    const needFilterMinPrice = Boolean(filterOptions.minPrice);
    const needFilterPriceRanges = Boolean(filterOptions.prices);
    const needFilterWeights = Boolean(filterOptions.weights);
    const needFilterTypes = Boolean(filterOptions.types);

    let priceRanges = null;
    if (needFilterPriceRanges) {
        priceRanges = splitToArray(filterOptions.prices).map((range) => {
            if (range.includes('<')) {
                return [0, Number(range.split('<')[1])];
            }
            if (range.includes('>')) {
                return [Number(range.split('>')[0]), Infinity];
            }
            return range.split('-').map(Number);
        })
    }
    return processedProducts.filter((product) => {
        const isActive = product.sts === 'ACTIVE';

        let isInCategory = true;
        // if (needFilterCategory) {
        //     const productCateGroup = categoryMappings[product.category].productCategoryGroup;
        //     isInCategory = splitToArray(filterOptions.categories).includes(productCateGroup);
        // }

        const isInBrands = needFilterBrands
            ? splitToArray(filterOptions.brands).includes(product.brand)
            : true;

        const isInMaxPrice = needFilterMaxPrice
            ? Number(filterOptions.maxPrice) >= Number(product.price)
            : true;

        const isInMinPrice = needFilterMinPrice
            ? Number(filterOptions.minPrice) <= Number(product.price)
            : true;

        const isInPriceRanges = needFilterPriceRanges
            ? priceRanges.some(range => product.price >= range[0] && product.price <= range[1])
            : true;

        const productWeight = `${product.volumn}${product.unit}`;
        const isInWeights = needFilterWeights
            ? splitToArray(filterOptions.weights).includes(productWeight)
            : true;

        const isInTypes = needFilterTypes
            ? splitToArray(filterOptions.types).includes(product.type)
            : true;

        return (isActive &&
            isInCategory) &&
            isInBrands &&
            isInMaxPrice &&
            isInMinPrice &&
            isInPriceRanges &&
            isInTypes &&
            isInWeights;
    })
}

const locationsFilter = (processedLocations, processedProfiles, filterOptions) => {
    if (!filterOptions) {
        return processedLocations;
    }
    const needFilterBusinessType = Boolean(filterOptions.businessTypes);
    const needFilterAmenity = Boolean(filterOptions.amenities);
    const needFilterProfiles = Boolean(filterOptions.profiles);

    return processedLocations.filter((location) => {
        const isActive = location.sts === 'ACTIVE';

        let isInProfiles = needFilterProfiles
            ? splitToArray(filterOptions.profiles).includes(location.profile_id)
            : true;

        let isInAmenities = true;
        if (needFilterAmenity) {
            const locationAmenityIds = location.amenities || [];
            const queryAmenityIds = splitToArray(filterOptions.amenities) || [];
            isInAmenities = locationAmenityIds.length > 0 &&
                queryAmenityIds.every(id => locationAmenityIds.indexOf(id) !== -1)
        }

        let isInBusinessType = true;
        if (needFilterBusinessType) {
            const locationProfileId = location.profile_id;
            const matchedProfile = processedProfiles ? processedProfiles.find(p => p.id === locationProfileId) : null;
            if (!matchedProfile) {
                isInBusinessType = false;
            } else {
                // TODO: use internal lambda api instead of extractId again;
                const businessTypeId = matchedProfile.biz_type;
                const queryBusinessTypes = splitToArray(filterOptions.businessTypes);
                isInBusinessType = queryBusinessTypes.indexOf(businessTypeId) !== -1;
            }
        }

        return isActive && isInAmenities && isInBusinessType && isInProfiles;
    });
}

const sendActivationEmailFromSendGrid = (email, id) => {

    const token = jwt.sign(
        {
            email,
            id
        },
        JWT_TOKEN,
        {
            expiresIn: '24h'
        }
    );
    const activationUrl = `https://www.damawish.com/activate?token=${token}`;

    return backend_api.post('https://api.sendgrid.com/v3/mail/send', {
        personalizations: [
            {
                to: [
                    {
                        email: email,
                        name: email.split('@')[0]
                    }
                ],
                dynamic_template_data: {
                    activationUrl
                }
            }
        ],
        from: {
            email: "info@damawish.com",
            name: "DamaWish"
        },
        reply_to: {
            email: "info@damawish.com",
            name: "Dama Wish"
        },
        template_id: "d-811c563abd8242768be7e932e370ca0e"
    }, {
        headers: {
            "Authorization": `Bearer ${SEND_GRID_TOKEN}`
        }
    });
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const toggleArray = (array, element) => {
    const index = array.indexOf(element);
    if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
    } else {
        array.push(element)
    }
    return array
}

const convertImgUrl = (url) => {
    let name = url.split('|')[2]
    return 'https://damawish-files-bucket-dev.s3.us-east-1.amazonaws.com/' + encodeURIComponent(name)
}

function sortByInitial(array, order) {
    array.sort(function(a, b) {
        if (!!a.name && !!b.name && a.name.length > 0 && b.name.length > 0) {
            let initialA = a.name.charAt(0).toLowerCase();
            let initialB = b.name.charAt(0).toLowerCase();
            if (order === 'asc') {
                if (initialA < initialB) {
                    return -1;
                } else if (initialA > initialB) {
                    return 1;
                }
                return 0;
            } else if (order === 'desc') {
                if (initialA > initialB) {
                    return -1;
                } else if (initialA < initialB) {
                    return 1;
                }
                return 0;
            } else {
                return 0
            }
        } else {
            console.log('invalid name !')
            return 0
        }

    });

    return array;
}

const sortByItem = (array, property, order) => {
    console.log('param: ', array, property, order)
    let tempArray = []
    for (let i = 0; i < array.length; i++) {
        tempArray.push(array[i])
    }
    if (tempArray.length > 1) {
        if (property === 'name') {
            tempArray = sortByInitial(tempArray, order)
        } else if (property === 'price') {
            tempArray.sort((a, b) => {
                if (property === 'price') {
                    if (order === 'desc') {
                        return b[property] - a[property]
                    } else {
                        return a[property] - b[property]
                    }
                }
            })
        }
    }
    return tempArray
}

const isObject = (variable) => {
    return typeof variable === 'object' &&
    !Array.isArray(variable) &&
    variable !== null

}

const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    if (time[0] < 10) {
        time[0] = '0' + time[0].toString()
    }
    let temp = time.join ('')
    temp = temp.substring(0, temp.length - 5) + temp.substring(temp.length - 2)
    return temp ; // return adjusted time or original string
}

const checkSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
        return parentArray.includes(el)
    })
}

const locate = (setGeo) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
                console.log('current position: ', position)
                setGeo({
                    located: true,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                })
            },
            err => {
                alert('Sorry, we\'re having trouble getting your location. Please check if your location service is enabled.')
                console.log('get current location error: ', err)
            }
        )
    } else {
        alert('Sorry, your browser does not support geolocation.')
    }
}

const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

const checkMobile = () => {
    return isMobile
}

module.exports = {
    createResponse,
    processPublicImg,
    extractId,
    productsFilter,
    locationsFilter,
    getOpenHours,
    splitToArray,
    createError,
    isNumeric,
    toggleArray,
    convertImgUrl,
    sortByItem,
    isObject,
    tConvert,
    checkSubset,
    locate,
    isValidEmail,
    checkMobile
}
