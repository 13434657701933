import Header from "components/blocks/Header";
import Footer from "components/blocks/Footer";
import AgeVerification from "components/AgeVerification";
import React from "react";

export default function DefaultLayout({children}) {

  return (
    <>
      <AgeVerification/>
      <Header/>
      {children}
      <Footer/>
    </>
  )
}
