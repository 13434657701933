import {Formik, useFormik} from 'formik'
import * as yup from 'yup'
import {Alert, Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {loginWithEmail} from "lib/features/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useRouter} from "next/router";


const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

export default function LoginForm({onSuccess}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (cred) => {
      setLoading(true)
      setShowError(false)
      dispatch(loginWithEmail(cred))
        .unwrap()
        .then(() => {
          setLoading(false)
          onSuccess()
        })
        .catch(() => {
          setShowError(true)
          setLoading(false)
        })
    }
  })

  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) return
    form.setFieldValue('email', router.query.email || '')
  }, [router.isReady])

  return (
    <div>
      {showError && <Alert severity={'error'} className={'w-full mb-4'}>
        Incorrect email or password.
      </Alert>}
      <form onSubmit={form.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
          className={'mb-4'}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={form.values.password}
          onChange={form.handleChange}
          error={form.touched.password && Boolean(form.errors.password)}
          helperText={form.touched.password && form.errors.password}
          className={'mb-4'}
        />
        <Button
          color="primary"
          className={'!bg-[#108085]'}
          variant="contained" size={'large'}
          fullWidth
          disabled={loading}
          startIcon={loading && <FontAwesomeIcon icon={solid('circle-notch')} className={'animate-spin-fast'}/>}
          type="submit">
          Login
        </Button>
      </form>
    </div>
  )
}
